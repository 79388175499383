import { QueryClient } from '@tanstack/react-query';

import {
  type HeaderProfileDropdownContentQuery,
  useHeaderProfileDropdownContentQuery,
} from '../../../utils/data/graphql/_generated/gql-generated';
import { getContentInCurrentLanguage } from '../../../utils/data/graphql/utils/get-content-in-current-language';
import { formatRelativePathLocale } from '../../../utils/helpers/formatters/relative-path-formatters';

export const getHeaderProfileDropdownContent = async () => {
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery<HeaderProfileDropdownContentQuery>({
    queryFn: useHeaderProfileDropdownContentQuery.fetcher(),
    queryKey: useHeaderProfileDropdownContentQuery.getKey(),
  });

  const siteSettingsPageItems = await getContentInCurrentLanguage(data.SiteSettingsPage?.items);

  const cmsContent = {
    ...siteSettingsPageItems?.[0],
  };

  return {
    accountLinkLabel: cmsContent.AccountLinkLabel || 'Account settings',
    accountSettingsPagePath:
      (await formatRelativePathLocale(
        cmsContent.AccountSettingsPageReference?.Expanded?.RelativePath
      )) || '',
    adminDasboardPageLinkLabel: cmsContent.AdminDashboardPageLinkLabel || 'Staff admin',
    adminDashboardPagePath: cmsContent.AdminDashboardPage?.Expanded?.RelativePath || '',
    becomeMemberLabel: cmsContent.BecomeMemberLabel || 'Become a member',
    companyAdminDasboarPagePath: cmsContent.CompanyAdminDashboardPage?.Expanded?.Url || '',
    companyAdminDashboardPageLinkLabel:
      cmsContent.CompanyAdminDashboardPageLinkLabel || 'Company admin',
    impersonateModalButtonLabel: cmsContent.ImpersonateModalButtonLabel || 'Impersonate',
    impersonateModalDescription:
      cmsContent.ImpersonateModalDescription ||
      "If you click Impersonate the person id will update, triggering a page reload, which will propagate that new user's context on the page.",
    impersonateModalErrorMessage:
      cmsContent.ImpersonateModalErrorMessage || "This person id doesn't exist",
    impersonateModalPersonIDLabel: cmsContent.ImpersonateModalPersonIDLabel || 'Person ID',
    impersonateModalTitle: cmsContent.ImpersonateModalTitle || 'Impersonation',
    impersonationLinkLabel: cmsContent.ImpersonationLinkLabel || 'Impersonate',
    mdrtId:
      cmsContent.MDRTId || '{addColon, select, true {MDRT ID: {mdrtId}} other {MDRT ID {mdrtId}}}',
    profileDropdownAriaLabel: cmsContent.ProfileDropdownAriaLabel || 'Profile Dropdown',
    profileDropdownTriggerAriaLabel:
      cmsContent.ProfileDropdownTriggerAriaLabel || 'User profile dropdown',
    selectLanguageLabel: cmsContent.SelectLanguageLine || 'Select language',
  };
};

export type HeaderAccountDropdownContent = Awaited<
  Promise<PromiseLike<ReturnType<typeof getHeaderProfileDropdownContent>>>
>;
