'use client';

import { logOut } from '@shared/misc';
import { Logout } from '@shared/ui-components';

import { loginHandler } from '../../utils/helpers';
import { Button } from '../button';
import { useGlobalCmsContentContext } from '../providers';

import styles from './authentication-button.module.scss';

export const AuthenticationButton = ({
  isAuthenticated,
  isMobile = false,
  locale,
}: {
  isAuthenticated: boolean;
  isMobile?: boolean;
  locale: string;
}) => {
  const { cmsContent } = useGlobalCmsContentContext();
  const loginButtonProps = {
    children: cmsContent.loginLabel,
    onClick: () => loginHandler({ authorizationParameters: { ui_locales: locale } }),
  };

  const logoutButtonProps = {
    children: cmsContent.logoutLabel,
    onClick: () => {
      logOut();
      sessionStorage.removeItem('settingsPreviewModalWasDisplayed');
    },
  };

  return isMobile ? (
    <Button
      className={styles['mobile-authentication-button']}
      data-testid={isAuthenticated ? 'log-out-button' : 'log-in-button'}
      {...(isAuthenticated ? logoutButtonProps : loginButtonProps)}
    />
  ) : isAuthenticated ? (
    <button
      className={styles['authentication-button']}
      data-testid="log-out-button"
      type="button"
      {...logoutButtonProps}
    >
      <Logout className={styles['authentication-button__icon']} />
      {logoutButtonProps.children}
    </button>
  ) : (
    <button
      className={styles['authentication-button-login']}
      data-testid="log-in-button"
      type="button"
      {...loginButtonProps}
    />
  );
};
