import { getUserHasAccessToEdgeAccessGroupContentFlag } from '../../../../utils/helpers/get-user-has-access-to-edge-access-group-content-flag';
import { getBlockSubmenuVisibilityByAccessGroup } from '../../../../utils/helpers/get-visibility-by-access-group';
import { type NavigationItem } from '../../../../utils/types/navigation-items';
import { type HeaderNavigationLinks } from '../../graphql';

import { removeLocaleFromRoute } from './remove-locale-from-route';

type MainContentItemLike = Nullable<{
  ContentLink?: Nullable<{
    Expanded?: Nullable<{
      AccessGroup?: Nullable<string>;
      AnchorLabel?: Nullable<string>;
      AnchorLink?: Nullable<string>;
      ShowOnlyForEdgeAccessGroupMembers?: Nullable<boolean>;
      __typename?: Nullable<string>;
    }>;
  }>;
}>;

type MembershipRequirementsBlockLike = {
  AccessGroup?: Nullable<string>;
  CertifyingLettersAnchorLabel?: Nullable<string>;
  CertifyingLettersAnchorLink?: Nullable<string>;
  EthicalCodeAnchorLabel?: Nullable<string>;
  EthicalCodeAnchorLink?: Nullable<string>;
  MembershipDuesAnchorLabel?: Nullable<string>;
  MembershipDuesAnchorLink?: Nullable<string>;
  ProductionRequirementsAnchorLabel?: Nullable<string>;
  ProductionRequirementsAnchorLink?: Nullable<string>;
};

const MEMBERSHIP_REQUIREMENT_ANCHORS: Array<{
  anchorLabel: keyof MembershipRequirementsBlockLike;
  anchorLink: keyof MembershipRequirementsBlockLike;
}> = [
  { anchorLabel: 'CertifyingLettersAnchorLabel', anchorLink: 'CertifyingLettersAnchorLink' },
  { anchorLabel: 'EthicalCodeAnchorLabel', anchorLink: 'EthicalCodeAnchorLink' },
  { anchorLabel: 'MembershipDuesAnchorLabel', anchorLink: 'MembershipDuesAnchorLink' },
  {
    anchorLabel: 'ProductionRequirementsAnchorLabel',
    anchorLink: 'ProductionRequirementsAnchorLink',
  },
];

const getMembershipRequirementsBlockNavItems = async <T extends MembershipRequirementsBlockLike>(
  membershipBlock: T
) => {
  const navLinks = [];
  const visibility = await getBlockSubmenuVisibilityByAccessGroup(membershipBlock.AccessGroup);

  for (const anchor of MEMBERSHIP_REQUIREMENT_ANCHORS) {
    const anchorLabel = membershipBlock?.[anchor.anchorLabel];
    const anchorLink = membershipBlock?.[anchor.anchorLink];

    if (anchorLabel && anchorLink) {
      navLinks.push({
        route: `#${anchorLink}`,
        title: anchorLabel,
        visible: visibility,
      });
    }
  }

  return navLinks;
};

const getSubmenuItems = async <T extends MainContentItemLike>(
  mainContent: T[]
): Promise<NavigationItem[]> => {
  const navLinks = [];

  const userHasAccessToEdgeAccessGroupContent =
    await getUserHasAccessToEdgeAccessGroupContentFlag();

  for (const block of mainContent) {
    const blockData = block?.ContentLink?.Expanded;

    if (!blockData) {
      continue;
    }

    if (blockData.AnchorLabel && blockData.AnchorLink) {
      const visibility = await getBlockSubmenuVisibilityByAccessGroup(blockData.AccessGroup);

      const shouldBeVisible =
        blockData.__typename === 'NextEventBlock'
          ? !blockData.ShowOnlyForEdgeAccessGroupMembers ||
            (blockData.ShowOnlyForEdgeAccessGroupMembers === true &&
              userHasAccessToEdgeAccessGroupContent)
          : visibility;

      navLinks.push({
        route: `#${blockData.AnchorLink}`,
        title: blockData.AnchorLabel,
        visible: shouldBeVisible,
      });
    }

    if (blockData.__typename === 'MembershipRequirementsBlock') {
      const membershipNavLinks = await getMembershipRequirementsBlockNavItems(
        blockData as MembershipRequirementsBlockLike
      );

      navLinks.push(...membershipNavLinks);
    }
  }

  return navLinks;
};

export const getNavigationLinksByPageType = async (
  data: HeaderNavigationLinks,
  locale: string
): Promise<NavigationItem[]> => {
  const content = data?.MenuLink || [];

  const navLinks = [];

  for (const page of content) {
    const pageData = page?.ContentLink?.Expanded;

    if (pageData?.__typename === 'LandingPage' && pageData?.Name && pageData?.Url) {
      navLinks.push({ route: pageData.Url, title: pageData.Name });
    }

    if (pageData?.__typename === 'LearnPageV2' && pageData?.Name && pageData?.RelativePath) {
      navLinks.push({
        route: removeLocaleFromRoute(pageData.RelativePath, locale),
        title: pageData.Name,
      });
    }

    if (pageData?.__typename === 'NextStaticPage' && pageData?.Name && pageData?.RelativePath) {
      navLinks.push({
        route: removeLocaleFromRoute(pageData.RelativePath, locale),
        ...(pageData.HasSecondaryNavigation &&
          pageData?.MainContent && {
            subMenu: await getSubmenuItems(pageData.MainContent),
          }),
        title: pageData.Name,
      });
    }
  }

  return navLinks;
};
