import { type DonationLevel } from '@shared/utils';

import { fetchData, withErrorHandling } from '../helpers';

import { OrgSharedDataTag } from './constants';

export const getDonationLevels = async (): Promise<DonationLevel[] | null> => {
  const response = await fetchData({
    apiUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/Donation/Levels`,
    headers: {
      'Ocp-Apim-Subscription-Key': process.env.NEXT_PUBLIC_OCP_API_SUBSCRIPTION_KEY ?? '',
    },
    next: {
      tags: [OrgSharedDataTag.GET_DONATION_LEVELS],
    },
  });

  if (response?.status === 404) {
    return null;
  }

  return withErrorHandling(
    response,
    // eslint-disable-next-line no-template-curly-in-string
    '${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/Donation/Levels'
  );
};
