import Image from 'next/image';
import { type ReactElement } from 'react';

import { NoPhoto } from '@shared/ui-components';
import { formatTranslationStringOnClient } from '@shared/utils';

import { type ProfileSummary } from '../../../../utils/types';
import { type HeaderAccountDropdownContent } from '../../graphql';

import styles from './profile-dropdown-summary.module.scss';

export const ProfileDropdownSummary = ({
  cmsContent,
  profileSummary,
  statusChipSlot,
}: {
  cmsContent: HeaderAccountDropdownContent;
  profileSummary: ProfileSummary;
  statusChipSlot?: ReactElement;
}) => {
  return (
    <div className={styles['profile-dropdown-summary']}>
      <div className={styles['profile-dropdown-summary__inner']}>
        <div className={styles['profile-dropdown-summary__photo']}>
          {profileSummary.photoUrl ? (
            <Image alt="" height={112} src={profileSummary.photoUrl} width={112} />
          ) : (
            <NoPhoto
              className={styles['profile-dropdown-summary__icon']}
              data-testid="profile-dropdown-photo-placeholder"
            />
          )}
        </div>
        <div
          className={styles['profile-dropdown-summary__name']}
          data-testid="profile-dropdown-display-name"
        >
          {profileSummary.displayName}
        </div>
        <div
          className={styles['profile-dropdown-summary__id']}
          data-testid="profile-dropdown-mdrt-id"
        >
          {formatTranslationStringOnClient(cmsContent.mdrtId, {
            addColon: true,
            mdrtId: profileSummary.mdrtId,
          })}
        </div>
        {statusChipSlot && (
          <div className={styles['profile-dropdown-summary__chip']}>{statusChipSlot}</div>
        )}
      </div>
    </div>
  );
};
