import { SeekButton, Tooltip } from '@vidstack/react';
import { SeekBackward10Icon, SeekForward10Icon } from '@vidstack/react/icons';
import classnames from 'classnames';

import styles from './seek-button-with-tooltip.module.scss';

export const SEEK_STEP = 10;

type SeekButtonWithTooltipProps = {
  label: string;
  type: 'forward' | 'backward';
};

export const SeekButtonWithTooltip = ({ label, type }: SeekButtonWithTooltipProps) => {
  const isForward = type === 'forward';

  return (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <SeekButton
          className={classnames('vds-button', styles['seek-btn'])}
          seconds={isForward ? SEEK_STEP : -SEEK_STEP}
        >
          {isForward ? (
            <SeekForward10Icon className="vds-icon" />
          ) : (
            <SeekBackward10Icon className="vds-icon" />
          )}
        </SeekButton>
      </Tooltip.Trigger>
      <Tooltip.Content className="vds-tooltip-content" placement="top start">
        {label}
      </Tooltip.Content>
    </Tooltip.Root>
  );
};
