import { GTM_EVENT_LABEL_IOS_APP } from '@shared/utils';

import { FALLBACK_LOCALES_LIST } from '../../../utils/constants';
import { Locale } from '../../../utils/enums/locale';

const appStoreBadges = [
  {
    imgSrc:
      'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1533427200&h=53dcc14d9a6e19a7d850d090f8bf0d60',
    locale: Locale.En,
  },
  {
    imgSrc:
      'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/es-es?size=250x83&amp;releaseDate=1533427200&h=9052ce291326461df49558f9ba8f075d',
    locale: Locale.Es,
  },
  {
    imgSrc:
      'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/id-id?size=250x83&amp;releaseDate=1533427200&h=a8660398833553c9620bf635d9d09674',
    locale: Locale.Id,
  },
  {
    imgSrc:
      'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/vi-vn?size=250x83&amp;releaseDate=1533427200&h=deafee9949ab60ef64e8637140ccd2f5',
    locale: Locale.Vi,
  },
  {
    imgSrc:
      'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/th-th?size=250x83&amp;releaseDate=1533427200&h=b2ac7e55f2d492b17438abcbe9cbc7c6',
    locale: Locale.Th,
  },
  {
    imgSrc:
      'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ja-jp?size=250x83&amp;releaseDate=1533427200&h=ca41888f2c0b2f19fe2e30f0c5a684f6',
    locale: Locale.Ja,
  },
  {
    imgSrc:
      'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/zh-cn?size=250x83&amp;releaseDate=1533427200&h=d64824cef5f373962d9fa3605a3b7bbc',
    locale: Locale.ZhCN,
  },
  {
    imgSrc:
      'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/zh-hk?size=250x83&amp;releaseDate=1533427200&h=dd86cfc18440fd0f7867b2a1b507d894',
    locale: Locale.ZhCHT,
  },
  {
    imgSrc:
      'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ko-kr?size=250x83&amp;releaseDate=1533427200&h=f390d76140bf4cb78bfe998bbca297ed',
    locale: Locale.Ko,
  },
];

export const AppStoreBadge = ({
  badgeAltText,
  className,
  locale,
  url,
}: {
  badgeAltText: string;
  className?: string;
  locale: string;
  url: string;
}) => {
  const badgeUrl = FALLBACK_LOCALES_LIST.includes(locale)
    ? appStoreBadges.find((item) => item.locale === locale)?.imgSrc
    : appStoreBadges[0]?.imgSrc;

  return (
    <a
      className={className}
      data-ga-app-download={GTM_EVENT_LABEL_IOS_APP}
      href={url}
      rel="noopener noreferrer"
      target="__blank"
    >
      <img alt={badgeAltText} height={61} src={badgeUrl} width={182} />
    </a>
  );
};
