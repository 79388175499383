'use client';

import classNames from 'classnames';
import { type PropsWithChildren, useEffect, useRef } from 'react';
import { FocusScope, Overlay, useModalOverlay } from 'react-aria';
import { type OverlayTriggerState } from 'react-stately';

import { Button, ButtonVariant, DrawerClose } from '@shared/ui-components';

import { useGlobalCmsContentContext } from '../providers';

import styles from './drawer.module.scss';

type DrawerProps = {
  className?: string;
  state: OverlayTriggerState;
};

export const Drawer = ({
  children,
  className,
  state,
  ...restProps
}: PropsWithChildren<DrawerProps>) => {
  const { cmsContent } = useGlobalCmsContentContext();
  const drawerRef = useRef<HTMLDivElement>(null);
  const drawerWrapperRef = useRef<HTMLDivElement>(null);
  const { modalProps, underlayProps } = useModalOverlay({ isDismissable: true }, state, drawerRef);

  // useEffect is used to prevent event propagation on overlay touch
  useEffect(() => {
    const handleTouchStart = (event: TouchEvent) => {
      const touchTarget = event.target as HTMLElement;

      if (!drawerRef.current?.contains(touchTarget)) {
        event.preventDefault();
      }
    };

    const drawerElementWrapper = drawerWrapperRef.current;
    if (drawerElementWrapper) {
      drawerElementWrapper.addEventListener('touchstart', handleTouchStart);
    }

    return () => {
      if (drawerElementWrapper) {
        drawerElementWrapper.removeEventListener('touchstart', handleTouchStart);
      }
    };
  }, []);

  return (
    <Overlay {...underlayProps}>
      <div className={styles['drawer-wrapper']} ref={drawerWrapperRef} {...restProps}>
        <FocusScope autoFocus contain restoreFocus>
          <div className={classNames(styles.drawer, className)} ref={drawerRef} {...modalProps}>
            <div className={styles['drawer__actions']}>
              <Button
                aria-label={cmsContent.closeButtonLabel}
                className={styles['drawer__close-button']}
                onClick={() => state.close()}
                variant={ButtonVariant.Plain}
              >
                <DrawerClose />
              </Button>
            </div>
            {children}
          </div>
        </FocusScope>
      </div>
    </Overlay>
  );
};
