export enum SocialIcon {
  Facebook = 'svg__span--facebook-footer',
  Instagram = 'svg__span--instagram-footer',
  Linkedin = 'svg__span--linkedin-footer',
  Pinterest = 'svg__span--pinterest-footer',
  Soundcloud = 'svg__span--soundcloud-footer',
  Twitter = 'svg__span--twitter-footer',
  Wordpress = 'svg__span--wordpress-footer',
  Youtube = 'svg__span--youtube-footer',
}
