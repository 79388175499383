'use client';

import { type DropdownMenuProps, DropdownMenu as SharedDropdownMenu } from '@shared/ui-components';

import { useContainerSpacingInline } from '../../utils/hooks/use-container-spacing-inline';

export const DropdownMenu = <T extends object>(props: DropdownMenuProps<T>) => {
  const containerPadding = useContainerSpacingInline();
  return <SharedDropdownMenu containerPadding={containerPadding} {...props} />;
};
