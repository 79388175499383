import { QueryClient } from '@tanstack/react-query';

import { useSiteSettingsGlobalContentQuery } from './graphql/_generated/gql-generated';
import { getContentInCurrentLanguage } from './graphql/utils';

const getBaseUrl = (url: string | undefined | null, relativePath: string | undefined | null) => {
  if (url && relativePath) {
    return url.slice(0, url.length - `${relativePath}/`.length);
  }

  return '';
};

export const getGlobalCmsContent = async () => {
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery({
    queryFn: useSiteSettingsGlobalContentQuery.fetcher(),
    queryKey: useSiteSettingsGlobalContentQuery.getKey(),
  });

  const siteSettingsPageItems = await getContentInCurrentLanguage(data.SiteSettingsPage?.items);
  const notFoundPageItems = (await getContentInCurrentLanguage(data.NotFoundPage?.items))?.[0];
  const errorPageItems = (await getContentInCurrentLanguage(data.ErrorPage?.items))?.[0];

  const cmsContent = {
    ...siteSettingsPageItems?.[0],
  };

  return {
    appDescription: cmsContent?.AppDescription || 'Million Dollar Round Table',
    backAriaLabel: cmsContent?.BackAriaLabel || 'Go back to the previous page',
    backLabel: cmsContent?.BackLabel || 'Back',
    carouselNextButtonLabel: cmsContent?.CarouselNextButtonLabel || 'Next slides',
    carouselPreviousButtonLabel: cmsContent?.CarouselPreviousButtonLabel || 'Previous slides',
    closeButtonLabel: cmsContent?.Close || 'Close',
    defaultRouteTitle: cmsContent?.DefaultRouteTitle || 'MDRT',
    errorPageTitle: errorPageItems?.Title || 'Something went wrong',
    goBackToHomePageLabel: cmsContent?.GoBackToHomepage || 'Go back to homepage',
    leavingConfirmMessage:
      cmsContent?.LeavingConfirmMessage || 'Changes you made may not be saved.',
    loginLabel: cmsContent?.LoginLinkLabel || 'Sign in',
    logoutLabel: cmsContent?.LogoutLine || 'Sign out',
    noLabel: cmsContent?.NoLabel || 'No',
    noOptionsLabel: cmsContent.NoResultsLabel || 'No results',
    notFoundPageTitle: notFoundPageItems?.Title || 'Not found page',
    optimizelyBaseUrl: getBaseUrl(cmsContent.Url, cmsContent.RelativePath),
    optionalLabel: cmsContent?.Optional || 'optional',
    somethingWentWrong: cmsContent?.ToastSomethingWentWrong || 'Something went wrong',
    successMessage: cmsContent?.DateSavedSuccessfully || 'Data saved successfully',
    yesLabel: cmsContent?.YesLabel || 'Yes',
  };
};

export type SiteSettingsGlobalContent = Awaited<
  Promise<PromiseLike<ReturnType<typeof getGlobalCmsContent>>>
>;
