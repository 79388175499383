import { QueryClient } from '@tanstack/react-query';

import {
  type HeaderNavigationLinksQuery,
  useHeaderNavigationLinksQuery,
} from '../../../utils/data/graphql/_generated/gql-generated';
import { getContentInCurrentLanguage } from '../../../utils/data/graphql/utils/get-content-in-current-language';

export const getHeaderNavigationLinks = async () => {
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery<HeaderNavigationLinksQuery>({
    queryFn: useHeaderNavigationLinksQuery.fetcher(),
    queryKey: useHeaderNavigationLinksQuery.getKey(),
  });

  return (await getContentInCurrentLanguage(data?.SiteSettingsPage?.items))?.[0];
};

export type HeaderNavigationLinks = Awaited<
  Promise<PromiseLike<ReturnType<typeof getHeaderNavigationLinks>>>
>;
