'use client';

import {
  type PropsWithChildren,
  createContext,
  useMemo,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';

const SubNavigationContext = createContext<{
  elements: null | Element[];
  registerElements: () => void;
  registerLinks: (links: string[]) => void;
} | null>(null);

const getLinkedElements = (linkIds: string[]) =>
  linkIds.map((id) => document.querySelector(`#${id}`)).filter(Boolean) as Element[];

export const SubNavigationContextProvider = ({ children }: PropsWithChildren) => {
  const [elements, setElements] = useState<Element[] | null>(null);
  const linksRef = useRef<string[] | null>(null);
  const elementsReadyRef = useRef<boolean>(false);

  const findAndSetLinkedElements = useCallback((links: string[]) => {
    const foundElements = getLinkedElements(links);

    if (foundElements.length) {
      setElements(foundElements);
    }
  }, []);

  const registerLinks = useCallback(
    (links: string[]) => {
      linksRef.current = links;

      if (!elementsReadyRef.current) {
        return;
      }

      findAndSetLinkedElements(links);
    },
    [findAndSetLinkedElements]
  );

  const registerElements = useCallback(() => {
    elementsReadyRef.current = true;

    if (!linksRef.current) {
      return;
    }

    findAndSetLinkedElements(linksRef.current);
  }, [findAndSetLinkedElements]);

  const value = useMemo(
    () => ({
      elements,
      registerElements,
      registerLinks,
    }),
    [elements, registerElements, registerLinks]
  );
  return <SubNavigationContext.Provider value={value}>{children}</SubNavigationContext.Provider>;
};

export const useSubNavigationContext = () => {
  const value = useContext(SubNavigationContext);

  if (!value) {
    throw new Error(
      `${useSubNavigationContext.name} must be used inside of ${SubNavigationContextProvider.name}`
    );
  }

  return value;
};
