'use client';

import { type StaticImport } from 'next/dist/shared/lib/get-img-props';

import { type ImageWithFallbackProps, ImageWithFallback } from '@shared/ui-components';

import { getStringWithQueryParameters } from '../../utils/helpers';

import DefaultFallbackImage from './fallback-image.svg';

type ImageWrapperProps = ImageWithFallbackProps & { hasDefaultFallback?: boolean };

export const ImageWrapper = ({
  fallbackSrc,
  hasDefaultFallback,
  src,
  ...rest
}: ImageWrapperProps) => {
  const width = rest.width ? Number(rest.width) : undefined;
  const height = rest.height ? Number(rest.height) : undefined;

  const transformImageSource = (imageSource: string | StaticImport) => {
    const isImgSourceDigizuite =
      (typeof imageSource === 'string' || imageSource instanceof String) &&
      imageSource.includes('/digizuite/');
    return isImgSourceDigizuite
      ? getStringWithQueryParameters(imageSource as string, { height, width })
      : imageSource;
  };

  const imageSourceTransformed = transformImageSource(src);
  const fallbackImageSourceTransformed = transformImageSource(
    fallbackSrc ? fallbackSrc : hasDefaultFallback ? DefaultFallbackImage : ''
  );

  return (
    <ImageWithFallback
      {...rest}
      fallbackSrc={fallbackImageSourceTransformed}
      src={imageSourceTransformed}
    />
  );
};
