import { logException } from '@shared/utils';

import { redirect } from '../../i18n/i18n-navigation';
import { ERROR_PATH, NOT_AUTHORIZED_PATH } from '../constants';

import { NotAuthorized } from './error-definitions';

export const withServerSideErrorHandling = async <T>(callback: () => Promise<T>) => {
  try {
    return await callback();
  } catch (error) {
    logException(error);
    if (error instanceof Error) {
      switch (error.constructor) {
        case NotAuthorized:
          redirect(NOT_AUTHORIZED_PATH);
          break;
        default:
          redirect(ERROR_PATH);
      }
    }

    throw new Error('Something went wrong.');
  }
};
