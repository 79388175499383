import { auth } from '@shared/misc';
import { logException } from '@shared/utils';

import {
  type CarouselRecommendationsResponse,
  type CarouselRecommendationsRequest,
} from '../types/carousel-recommendations';

export const postCarouselRecommendations = async ({
  request,
  xForwardedForHeader,
}: {
  request: CarouselRecommendationsRequest;
  xForwardedForHeader: string;
}): Promise<CarouselRecommendationsResponse> => {
  try {
    const session = await auth();

    const result = await fetch(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}/${
        process.env.NEXT_PUBLIC_API_RECOMMENDATIONS_ROUTE ?? 'recommendations-api'
      }/Recommendations`,
      {
        body: JSON.stringify(request),
        cache: 'no-store',
        headers: {
          Authorization: `Bearer ${session?.accessToken}`,
          'Ocp-Apim-Subscription-Key':
            process.env.NEXT_PUBLIC_OCP_RECOMMENDATIONS_SUBSCRIPTION_KEY ?? '',
          'X-Forwarded-For': xForwardedForHeader,
        },
        method: 'POST',
      }
    );

    if (!result.ok) {
      return {
        contentCategories: [],
      };
    }

    return result.json();
  } catch (error) {
    logException(error);
    return {
      contentCategories: [],
    };
  }
};
