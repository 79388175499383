import classnames from 'classnames';
import { type HTMLAttributes } from 'react';

import styles from './table.module.scss';

export const Table = ({ children, className, ...rest }: HTMLAttributes<HTMLTableElement>) => {
  return (
    <table className={classnames(styles.table, className)} {...rest}>
      {children}
    </table>
  );
};
