'use client';

import { useCallback, useEffect, useRef, useState } from 'react';

import { useViewportHeight } from '@shared/utils';

import { useSubNavigationContext } from '../../../providers/sub-navigation-context';

const FOCUS_LEVEL = 200;
const FOCUS_AREA_HEIGHT = 1; // using 1px height to avoid having two elements intersecting simultaneously

const getObserverOptions = (viewPortHeight: number) => ({
  rootMargin: `-${FOCUS_LEVEL}px 0px -${viewPortHeight - FOCUS_LEVEL - FOCUS_AREA_HEIGHT}px 0px`,
});

type VisibleElement = string | null;
type SetPriorityElement = (elementId: string, expirationTime?: number) => void;

export const useVisibleElement = ({
  elementIds,
  disabled = false,
}: {
  disabled?: boolean;
  elementIds: string[];
}): [VisibleElement, SetPriorityElement] => {
  const viewPortHeight = useViewportHeight();
  const { elements, registerLinks } = useSubNavigationContext();
  const [visibleElement, setVisibleElement] = useState<VisibleElement>(null);
  const priorityElement = useRef<{ hasEntered: boolean } | null>(null);
  const visibleNonPriorityElement = useRef<VisibleElement | null>(null);

  useEffect(() => {
    if (!disabled) {
      registerLinks(elementIds);
    }
  }, [disabled, elementIds, registerLinks]);

  useEffect(() => {
    if (!viewPortHeight || !elements || disabled) {
      return () => undefined;
    }

    const onIntersect: IntersectionObserverCallback = (entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          visibleNonPriorityElement.current = entry.target.id;
          if (!priorityElement.current) {
            setVisibleElement(entry.target.id);
          }

          continue;
        }

        if (!priorityElement) {
          setVisibleElement(visibleNonPriorityElement.current);
        }
      }
    };

    const options = getObserverOptions(viewPortHeight);

    const observer = new IntersectionObserver(onIntersect, options);

    for (const element of elements) {
      observer.observe(element);
    }

    return () => observer.disconnect();
  }, [disabled, elementIds, elements, viewPortHeight]);

  // this function can be used to temporarily track elements that are below the focus level (elements at the bottom of the page)
  const setPriorityElement = useCallback(
    (elementId: string, expirationTime = 1000) => {
      if (!elements) {
        return;
      }

      const foundElement = elements.find((element) => element.id === elementId);

      if (!foundElement) {
        return;
      }

      priorityElement.current = { hasEntered: false };

      const onPriorityElementIntersect: IntersectionObserverCallback = (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            priorityElement.current = { hasEntered: true };
            setVisibleElement(entry.target.id);
            continue;
          }

          if (!entry.isIntersecting && priorityElement.current?.hasEntered) {
            priorityElement.current = null;
            setVisibleElement(visibleNonPriorityElement.current);
          }
        }
      };

      const observer = new IntersectionObserver(onPriorityElementIntersect);
      observer.observe(foundElement);

      setTimeout(() => {
        observer.disconnect();
        priorityElement.current = null;
      }, expirationTime);
    },
    [elements]
  );

  return [visibleElement, setPriorityElement];
};
