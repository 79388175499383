import { type ServerActionResponse } from '@shared/utils';

class FetchError extends Error {
  private errorData: ServerActionResponse;

  constructor(errorData: ServerActionResponse) {
    super(errorData.message);
    this.errorData = errorData;
  }
}

export class ValidationError extends FetchError {
  constructor({ message, status, messageTranslationKey }: ServerActionResponse) {
    super({ message, messageTranslationKey, status: status ?? 400 });
    this.name = this.constructor.name;
  }
}

export class NotFoundError extends FetchError {
  constructor(message = 'Resource not found') {
    super({ message, status: 404 });
    this.name = this.constructor.name;
  }
}

export class NotAuthenticated extends FetchError {
  constructor(message = 'Not authenticated') {
    super({ message, status: 401 });
    this.name = this.constructor.name;
  }
}

export class NotAuthorized extends FetchError {
  constructor(message = 'Not authorized') {
    super({ message, status: 403 });
    this.name = this.constructor.name;
  }
}

export class InternalServerError extends FetchError {
  constructor(message = 'Internal server error') {
    super({ message, status: 500 });
    this.name = this.constructor.name;
  }
}
