import { getApplicationDefinition } from '../data/get-application-definition';
import { getCustomerProfile } from '../data/get-customer-profile';
import { getMembershipApplication } from '../data/get-membership-application';

export const getCardsSectionData = async (personId: number | undefined) => {
  const [applicationDefinitionData, customerProfileData] = await Promise.all([
    getApplicationDefinition(),
    getCustomerProfile({ personId }),
  ]);

  const year = applicationDefinitionData?.membershipYear;

  const [membershipApplicationData, lastYearMembershipApplicationData] = await Promise.all([
    getMembershipApplication({ personId, year }),
    getMembershipApplication({ personId, year: year - 1 }),
  ]);

  return {
    applicationDefinitionData,
    customerProfileData,
    lastYearMembershipApplicationData,
    membershipApplicationData,
  };
};
