import { QueryClient } from '@tanstack/react-query';

import { useLanguagesContentQuery } from '../data/graphql/_generated/gql-generated';
import { getContentInCurrentLanguage } from '../data/graphql/utils/get-content-in-current-language';
import { isNonNullable } from '../helpers/type-compatible-filter-functions';

const languagesListFallback = [
  { languageId: 'en', languageName: 'English' },
  { languageId: 'id', languageName: 'Bahasa Indonesia' },
  { languageId: 'es', languageName: 'Español' },
  { languageId: 'vi', languageName: 'Tiếng Việt' },
  { languageId: 'th', languageName: 'ภาษาไทย' },
  { languageId: 'zh-CHT', languageName: '繁體中文' },
  { languageId: 'zh-CN', languageName: '中文(中国)' },
  { languageId: 'ko', languageName: '한국어' },
  { languageId: 'ja', languageName: '日本語' },
];

const getContent = async () => {
  const queryClient = new QueryClient();

  return await queryClient.fetchQuery({
    queryFn: useLanguagesContentQuery.fetcher(),
    queryKey: useLanguagesContentQuery.getKey(),
  });
};

export const getAllTranslatableLanguageCodes = async () => {
  const cmsContent = await getContent();

  const languageIdsAndNames = cmsContent?.SiteSettingsPage?.items?.[0]?.LanguageIdsAndNames;

  if (languageIdsAndNames && languageIdsAndNames.length) {
    const languageCodes = languageIdsAndNames.map((languageEntry) =>
      languageEntry?.ContentLink?.Expanded?.__typename === 'LanguageIdAndName'
        ? languageEntry?.ContentLink?.Expanded.LanguageId
        : null
    );
    const filteredLanguageCodes = languageCodes.filter(isNonNullable);
    return [...new Set(filteredLanguageCodes)];
  } else {
    return languagesListFallback.map((language) => language.languageId);
  }
};

export const getLanguagesContent = async () => {
  const data = await getContent();

  const siteSettingsPageItems = await getContentInCurrentLanguage(data.SiteSettingsPage?.items);
  const languagesList = siteSettingsPageItems?.[0]?.LanguageIdsAndNames?.map((item) =>
    item?.ContentLink?.Expanded?.__typename === 'LanguageIdAndName'
      ? item?.ContentLink?.Expanded
      : null
  );
  const cmsContent = {
    ...siteSettingsPageItems?.[0],
    languagesList: languagesList?.map((languageEntry) => ({
      languageId: languageEntry?.LanguageId,
      languageName: languageEntry?.LanguageName,
    })),
  };

  return {
    languagesList: cmsContent.languagesList || languagesListFallback,
    selectLanguage: cmsContent.SelectLanguage || 'Select Language',
  };
};

export type LanguagesContent = Awaited<
  Promise<PromiseLike<ReturnType<typeof getLanguagesContent>>>
>;
