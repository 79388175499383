'use client';

import { sendGTMEvent } from '@next/third-parties/google';
import { useEffect, useMemo } from 'react';

import { ContentType } from '../../utils/data/constants';
import {
  type ExpandedSourcesEntry,
  type ExpandedTopicsEntry,
} from '../../utils/data/graphql/_generated/gql-generated';

type PageViewEventProps = {
  content: any;
  profileData: {
    company: string;
    isAuthorized: boolean;
    isMdrtStaff: boolean;
    isMember: boolean;
    memberId: string;
    memberLevel: string;
  };
};

const GTM_EVENT_PAGE_VIEW = 'pageView';
const LEARN_DETAIL_PAGE_TYPE = 'LearnDetailPage';

export const PageViewEvent = ({ content, profileData }: PageViewEventProps) => {
  const { company, isAuthorized, isMdrtStaff, memberId, memberLevel } = profileData;
  const isLearnDetailPage = useMemo(
    () => content?.__typename === LEARN_DETAIL_PAGE_TYPE,
    [content]
  );

  useEffect(() => {
    const pageViewEventData = {
      campaign: content?.AnalyticsCampaignName ?? '',
      company,
      contentId: content?.ContentLink.GuidValue,
      contentTypeId: content.ContentTypeID ?? '',
      event: GTM_EVENT_PAGE_VIEW,
      isAuthorized,
      isMdrtStaff,
      language: content?.Language.Name,
      languageBranchId: isLearnDetailPage ? content?.LanguageBranchId : '',
      masterLanguage: content?.MasterLanguage.Name,
      memberId,
      memberLevel,
      pageName: content?.Name,
      sources: isLearnDetailPage
        ? content?.ExpandedSources?.map((source: ExpandedSourcesEntry) => source.Name).join(', ')
        : '',
      topics: isLearnDetailPage
        ? content?.ExpandedTopics?.map((topic: ExpandedTopicsEntry) => topic.Name).join(', ')
        : '',
      type: isLearnDetailPage ? ContentType[content?.ContentTypeSelection] : '',
      year: isLearnDetailPage ? content?.DisplayedDateYear : '',
    };

    /*
    NOTE: there is an existing issue as page view event doesn't fire on initial page load - https://github.com/vercel/next.js/issues/21037
    This approach also requires to turn off the Enhanced measurement in GA (Page changes based on browser history events - should be false)
    to avoid double page view events to fire
    the setting is located in GA -> Admin -> Data collection and modification -> Data streams -> Select the stream -> Enhanced measurement -> Page views
    */
    const delay = setTimeout(() => sendGTMEvent(pageViewEventData), 0);

    return () => clearTimeout(delay);
  }, [company, content, isAuthorized, isLearnDetailPage, isMdrtStaff, memberId, memberLevel]);

  return null;
};
