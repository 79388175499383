'use client';

import classNames from 'classnames';
import { useState, type ReactNode, type MouseEvent, useMemo, useEffect, useRef } from 'react';

import { ChevronRight } from '@shared/ui-components';
import { useMounted } from '@shared/utils';

import { Link, usePathname } from '../../../../i18n';
import { useBreakpoint } from '../../../../utils/hooks/use-breakpoint';
import { type NavigationItem } from '../../../../utils/types/navigation-items';
import { useUserContext } from '../../../providers/user-context';
import { getCurrentPageSubItemIds } from '../../utils/helpers';
import { useVisibleElement } from '../../utils/hooks';

import { SubMenu } from './sub-menu';
import styles from './navigation.module.scss';

type NavigationProps = {
  authenticationSection?: ReactNode;
  close?: () => void;
  dropdownItemsSlot?: ReactNode;
  isMobile?: boolean;
  isSecondaryTheme?: boolean;
  languageSwitcherSlot?: ReactNode;
  navigationItems: NavigationItem[];
  profileSummarySlot?: ReactNode;
};

export const Navigation = ({
  authenticationSection,
  close,
  dropdownItemsSlot,
  isMobile = false,
  isSecondaryTheme,
  languageSwitcherSlot,
  navigationItems,
  profileSummarySlot,
  ...props
}: NavigationProps) => {
  const pathname = usePathname();
  const isMounted = useMounted();
  const { status } = useUserContext();
  const isAuthenticated = status === 'authenticated';
  const [expandedMenuItem, setExpandedMenuItem] = useState<string | undefined>(undefined);
  const observerRef = useRef<MutationObserver | null>(null);

  const subItemIds = useMemo(
    () => getCurrentPageSubItemIds(navigationItems, pathname),
    [navigationItems, pathname]
  );

  const { isDesktop } = useBreakpoint();
  const [visibleSection, setPriorityOnSection] = useVisibleElement({
    disabled: isMobile || !isDesktop,
    elementIds: subItemIds,
  });

  const handleMenuItemClick = (
    event: MouseEvent<HTMLAnchorElement>,
    navigationItem: NavigationItem
  ) => {
    if (isMobile && navigationItem.subMenu && navigationItem.subMenu.length > 0) {
      event.preventDefault();
      setExpandedMenuItem(
        expandedMenuItem === navigationItem.title ? undefined : navigationItem.title
      );
    } else {
      close?.();
    }
  };

  const handleSubMenuItemClick = (_event: MouseEvent<HTMLAnchorElement>, itemName: string) => {
    setPriorityOnSection(itemName);
    close?.();
  };

  useEffect(() => {
    const urlHash = window.location.hash;

    if (!urlHash) {
      return;
    }

    const isPageSection = subItemIds.includes(urlHash.replace('#', ''));

    if (!isPageSection) {
      return;
    }

    const scrollToSection = () => {
      const section = document.querySelector(urlHash);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        observerRef.current?.disconnect();
      }
    };

    scrollToSection();

    const mainElement = document.querySelector('main');

    if (!mainElement) return;

    const observerCallback = (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'attributes') {
          scrollToSection();
        }
      }
    };

    const observer = new MutationObserver(observerCallback);
    observer.observe(mainElement, { childList: true, subtree: true });
    observerRef.current = observer;

    // eslint-disable-next-line consistent-return
    return () => observer.disconnect();
  }, [isMounted, subItemIds]);

  return (
    <div className={styles['navigation-wrapper']}>
      {profileSummarySlot && isAuthenticated && profileSummarySlot}
      <nav
        className={classNames(styles.navigation, {
          [styles['navigation--secondary']]: isSecondaryTheme,
          [styles['navigation--not-authenticated']]: !isAuthenticated,
        })}
        {...props}
      >
        <ul className={styles['navigation__list']}>
          {navigationItems.map((navigationItem) => {
            const showDesktopSubMenu = navigationItem.subMenu && navigationItem.route === pathname;
            return (
              <li className={styles['navigation__list-item']} key={navigationItem.title}>
                <Link
                  aria-expanded={expandedMenuItem === navigationItem.title}
                  className={classNames(styles['navigation__list-item-link'], {
                    [styles['navigation__list-item-link--expanded']]:
                      expandedMenuItem === navigationItem.title,
                    [styles['navigation__list-item-link--active']]:
                      pathname === navigationItem.route,
                  })}
                  href={navigationItem.route}
                  onClick={(event) => handleMenuItemClick(event, navigationItem)}
                >
                  {navigationItem.title}
                  {isMobile && navigationItem.subMenu && navigationItem.subMenu.length > 0 && (
                    <ChevronRight
                      className={classNames(styles['navigation__list-item-icon'], {
                        [styles['navigation__list-item-icon--expanded']]:
                          expandedMenuItem === navigationItem.title,
                      })}
                    />
                  )}
                </Link>
                {(isMobile || showDesktopSubMenu) && (
                  <SubMenu
                    activeSubItem={visibleSection}
                    isExpanded={expandedMenuItem === navigationItem.title}
                    navItem={navigationItem}
                    onClick={handleSubMenuItemClick}
                  />
                )}
              </li>
            );
          })}
        </ul>
        {(languageSwitcherSlot || dropdownItemsSlot) && (
          <ul className={classNames(styles['navigation__list-secondary'])}>
            {languageSwitcherSlot && <li>{languageSwitcherSlot}</li>}
            {isAuthenticated && dropdownItemsSlot}
          </ul>
        )}
      </nav>
      {!isAuthenticated && (
        <div className={styles['navigation__authentication']}>{authenticationSection}</div>
      )}
    </div>
  );
};
