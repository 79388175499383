'use client';

/* eslint-disable import/no-unassigned-import */
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/audio.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
/* eslint-enable import/no-unassigned-import */
import { MediaPlayer, MediaProvider } from '@vidstack/react';
import {
  DefaultAudioLayout,
  defaultLayoutIcons,
  DefaultVideoLayout,
} from '@vidstack/react/player/layouts/default';
import { useEffect } from 'react';

import { SEEK_STEP, SeekButtonWithTooltip } from './components';
import { type PlayerCmsContent } from './graphql/get-player-cms-content';
import { getPlayerDefaultLayoutTranslations } from './utils';
import styles from './player.module.scss';

type PlayerProps = {
  cmsContent: PlayerCmsContent;
  src: string;
};

export const Player = ({ cmsContent, src }: PlayerProps) => {
  const translations = getPlayerDefaultLayoutTranslations(cmsContent);

  useEffect(() => {
    const videoElement = document.querySelector('video');

    const disableContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };

    const disableLongPress = (event: TouchEvent) => {
      event.preventDefault();
    };

    if (videoElement) {
      videoElement.setAttribute('controlsList', 'nodownload');
      videoElement.addEventListener('contextmenu', disableContextMenu);
      videoElement.addEventListener('touchstart', disableLongPress);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('contextmenu', disableContextMenu);
        videoElement.removeEventListener('touchstart', disableLongPress);
      }
    };
  }, []);

  return (
    <div className={styles.container}>
      <MediaPlayer crossOrigin playsInline src={src}>
        <MediaProvider />
        <DefaultAudioLayout
          colorScheme="light"
          icons={defaultLayoutIcons}
          translations={translations}
        />
        <DefaultVideoLayout
          colorScheme="light"
          icons={defaultLayoutIcons}
          seekStep={SEEK_STEP}
          slots={{
            afterPlayButton: (
              <SeekButtonWithTooltip label={translations['Seek Forward']} type="forward" />
            ),
            beforePlayButton: (
              <SeekButtonWithTooltip label={translations['Seek Backward']} type="backward" />
            ),
            downloadButton: null,
            pipButton: null,
          }}
          translations={translations}
        />
      </MediaPlayer>
    </div>
  );
};
