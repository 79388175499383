import {
  GENERIC_ERROR_MESSAGE_FALLBACK,
  GENERIC_ERROR_STATUS,
  GENERIC_ERROR_TRANSLATION_KEY,
  logException,
} from '@shared/utils';

import { type AssessmentQuestionsTopic } from '../enums/assessment-questions-topic';
import { getPublicEnvironmentVariables } from '../helpers/get-public-environment-variables';
import { invalidateAssessmentQuestionsTag } from '../helpers/invalidate-assessment-questions-tag';
import { type MutationError } from '../types/mutation-error';

export type PatchAssessmentQuestions = {
  questions: Array<{ questionId: string; score: number }>;
  topic: AssessmentQuestionsTopic;
};

export const patchAssessmentQuestions = async ({
  personId,
  formData,
  abortSignal,
  accessToken,
}: {
  abortSignal: AbortSignal;
  accessToken: string | undefined;
  formData: PatchAssessmentQuestions;
  personId: number | string | undefined;
}): Promise<Response | null | MutationError> => {
  try {
    const publicEnvironmentVariables = await getPublicEnvironmentVariables();

    const response = await fetch(
      `${publicEnvironmentVariables.apiBaseUrl}/${publicEnvironmentVariables.apiRoute}/CustomerProfile/${personId}/AssessmentQuestions`,
      {
        body: JSON.stringify(formData),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Ocp-Apim-Subscription-Key': publicEnvironmentVariables.ocpApiSubscriptionKey ?? '',
        },
        method: 'PATCH',
        signal: abortSignal,
      }
    );

    if (response.status !== 200) {
      const resolvedResponse = await response.json();

      return {
        message: resolvedResponse.message || response.statusText,
        messageTranslationKey: resolvedResponse.messageTranslationKey || '',
        status: response.status,
      };
    }

    invalidateAssessmentQuestionsTag(personId);

    return { status: 200 };
  } catch (error) {
    logException(error);

    if (abortSignal.aborted) {
      return null;
    }

    return {
      message: GENERIC_ERROR_MESSAGE_FALLBACK,
      messageTranslationKey: GENERIC_ERROR_TRANSLATION_KEY,
      status: GENERIC_ERROR_STATUS,
    };
  }
};
