import { type PropsWithChildren } from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './carousel-skeleton.module.scss';

export const CarouselSkeleton = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles['carousel-skeleton']}>
      <div className={styles['carousel-skeleton__title-skeleton-wrapper']}>
        <Skeleton className={styles['carousel-skeleton__title-skeleton']} height={40} width={250} />
      </div>

      <div className={styles['carousel-skeleton__card-row']}>{children}</div>
    </div>
  );
};
