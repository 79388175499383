import { type SelectFieldProps, SelectField as BaseSelectField } from '@shared/ui-components';

import { useGlobalCmsContentContext } from '../providers/global-cms-context';

export const SelectField = (props: SelectFieldProps) => {
  const { cmsContent } = useGlobalCmsContentContext();

  return (
    <BaseSelectField
      {...props}
      noOptionsLabel={cmsContent.noOptionsLabel}
      optionalLabel={cmsContent.optionalLabel}
    />
  );
};
