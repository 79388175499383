import { DEFAULT_LOCALE } from '@module/mdrt-org/shared/i18n';

export const removeLocaleFromRoute = (route: string, locale: string) => {
  if (locale === DEFAULT_LOCALE) {
    return route;
  }

  if (route.startsWith(`/${locale}/`)) {
    return route.replace(`/${locale}/`, '/');
  }

  return route;
};
