import { getLocale } from 'next-intl/server';

import { DEFAULT_LOCALE } from '../../../../i18n/locales';
import { getLocalPaths } from '../../get-local-paths';

export const getDashboardRelativePathInCMS = async () => {
  const localeURLSegment = await getLocale();
  const localeURLPrefix = localeURLSegment === DEFAULT_LOCALE ? '' : `/${localeURLSegment}`;
  const paths = await getLocalPaths();

  return `${localeURLPrefix}${paths.dashboardPagePath}`;
};
