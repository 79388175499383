import { type MembershipLevel } from '../enums';
import { isInGracePeriod, isMemberForCurrentMembershipYear } from '../helpers';
import {
  type CustomerProfile,
  type MembershipApplicationDto,
  type ApplicationDefinition,
  MembershipApplicationStatus,
} from '../types';

type getCurrentMembershipLevelArgs = {
  applicationDefinition: ApplicationDefinition;
  currentYearMembership: MembershipApplicationDto | null;
  customerProfile: CustomerProfile;
  lastYearMembership: MembershipApplicationDto | null;
};

export const getCurrentMembershipLevel = ({
  applicationDefinition,
  customerProfile,
  currentYearMembership,
  lastYearMembership,
}: getCurrentMembershipLevelArgs): MembershipLevel | undefined | null => {
  const hasCurrentYearMembership = isMemberForCurrentMembershipYear({
    applicationDefinition,
    currentYearMembership,
    customerProfile,
  });

  if (currentYearMembership && hasCurrentYearMembership) {
    if (currentYearMembership.orders && currentYearMembership.orders.length > 0) {
      const lastShippedOrder = currentYearMembership.orders
        .filter((order) => order.isShipped)
        .pop();

      if (lastShippedOrder) {
        return lastShippedOrder.level;
      }
    }

    if (currentYearMembership.status === MembershipApplicationStatus.Submitted) {
      return currentYearMembership.membershipLevelStepData.membershipLevel;
    } else {
      return currentYearMembership.previousMembershipLevel;
    }
  }

  const inGracePeriod = isInGracePeriod({ customerProfile, lastYearMembership });

  if (lastYearMembership && inGracePeriod) {
    if (lastYearMembership.orders && lastYearMembership.orders.length > 0) {
      const lastShippedOrder = lastYearMembership.orders.filter((order) => order.isShipped).pop();

      if (lastShippedOrder) {
        return lastShippedOrder.level;
      }
    }

    if (lastYearMembership.status === MembershipApplicationStatus.Submitted) {
      return lastYearMembership.membershipLevelStepData.membershipLevel;
    } else {
      return lastYearMembership.previousMembershipLevel;
    }
  }

  return undefined;
};
