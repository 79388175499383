import { auth } from '@shared/misc';

import { EdgeAccessGroupCountryIds } from '../constants/accessGroups';
import { getCountries } from '../data/get-countries';
import { getCustomerProfile } from '../data/get-customer-profile';
import { getIpBasedLocation } from '../data/get-ip-based-location';

export const getUserHasAccessToEdgeAccessGroupContentFlag = async () => {
  const session = await auth();
  const personId = session?.personId;

  if (personId) {
    const customerProfile = await getCustomerProfile({ personId });

    return customerProfile?.countryId
      ? EdgeAccessGroupCountryIds.includes(customerProfile?.countryId)
      : false;
  }

  const [countries, countryByIp] = await Promise.all([getCountries(), getIpBasedLocation()]);
  const countryIdByIp = countries.find(
    (country) => country.isoCode === countryByIp?.countryCode
  )?.countryId;

  return countryIdByIp ? EdgeAccessGroupCountryIds.includes(countryIdByIp) : false;
};
