import { usePathname, useRouter } from '../../i18n/i18n-navigation';

export const useLanguageSwitcher = () => {
  const router = useRouter();
  const pathname = usePathname();

  const onLanguageChange = (locale: string) => {
    router.replace(pathname, { locale });
    router.refresh();
  };

  return { onLanguageChange };
};
