'use client';

import { useEffect } from 'react';

import { parseJwtToken } from '@shared/misc';
import {
  GTM_ACTION_FORM_SUBMISSION,
  GTM_EVENT_NAME_SUCCESSFUL_SIGNIN,
  GTM_EVENT_SEND_SUCCESS_LOGIN_KEY,
  MDRT_ID_COOKIE_NAME,
  sendGtmEvent,
} from '@shared/utils';

import { setCookie } from '../../utils/helpers';
import { useUserContext } from '../providers';

export const GtmScript = () => {
  const { accessToken, personId, status } = useUserContext();

  useEffect(() => {
    if (accessToken && sessionStorage.getItem(GTM_EVENT_SEND_SUCCESS_LOGIN_KEY)) {
      sendGtmEvent({
        action: GTM_ACTION_FORM_SUBMISSION,
        eventName: GTM_EVENT_NAME_SUCCESSFUL_SIGNIN,
        label: GTM_EVENT_NAME_SUCCESSFUL_SIGNIN,
      });

      const parsedToken = accessToken ? parseJwtToken(accessToken) : undefined;
      const mdrtId = parsedToken?.MDRTId;
      if (mdrtId) {
        setCookie(MDRT_ID_COOKIE_NAME, mdrtId.toString());
      }

      if (status !== 'loading') {
        sessionStorage.removeItem(GTM_EVENT_SEND_SUCCESS_LOGIN_KEY);
      }
    }
  }, [accessToken, personId, status]);

  return null;
};
