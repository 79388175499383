import { getPublicEnvironmentVariables } from '../helpers/get-public-environment-variables';
import { fetchWithErrorHandling } from '../http-services';
import { type State } from '../types';

import { OrgSharedDataTag } from './constants/tags';

export const getCountryStates = async ({ countryId }: { countryId: Nullable<number> }) => {
  const environment = await getPublicEnvironmentVariables(); // todo add error handling for server action

  return await fetchWithErrorHandling<State[]>(
    `${environment.apiBaseUrl}/${environment.profileRoute}/States/${countryId}`,
    {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApiSubscriptionKey ?? '',
      },
      next: {
        tags: [
          OrgSharedDataTag.GET_COUNTRY_STATES,
          `${OrgSharedDataTag.GET_COUNTRY_STATES}_${countryId}`,
        ],
      },
    }
  );
};
