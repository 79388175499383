import { getPublicEnvironmentVariables } from '../helpers/get-public-environment-variables';
import { fetchWithErrorHandling } from '../http-services';
import { type Country } from '../types/countries';

import { OrgSharedDataTag } from './constants/tags';

export const getCountries = async () => {
  const environment = await getPublicEnvironmentVariables(); // todo add error handling for server action

  return await fetchWithErrorHandling<Country[]>(
    `${environment.apiBaseUrl}/${environment.membershipRoute}/Countries`,
    {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApiSubscriptionKey ?? '',
      },
      next: { tags: [OrgSharedDataTag.GET_COUNTRIES] },
    }
  );
};
