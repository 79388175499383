'use client';

import classNames from 'classnames';

import { Link } from '../../../../../i18n';
import { type NavigationItem } from '../../../../../utils/types/navigation-items';

import styles from './sub-menu.module.scss';
import type { MouseEvent } from 'react';

type SubMenuProps = {
  activeSubItem: Nullable<string>;
  isExpanded: boolean;
  navItem: NavigationItem;
  onClick?: (event: MouseEvent<HTMLAnchorElement>, itemName: string) => void;
};

export const SubMenu = ({ activeSubItem, isExpanded, navItem, onClick }: SubMenuProps) => {
  return (
    <ul
      className={classNames(styles['sub-menu'], {
        [styles['sub-menu--expanded']]: isExpanded,
      })}
    >
      {navItem.subMenu?.map(
        (subMenuItem) =>
          subMenuItem.visible && (
            <li key={subMenuItem.title}>
              <Link
                className={classNames(styles['sub-menu__item'], {
                  [styles['sub-menu__item--active']]: subMenuItem.route === `#${activeSubItem}`,
                })}
                data-submenu-item
                href={navItem.route + subMenuItem.route}
                onClick={(event) => onClick?.(event, subMenuItem.route.replace('#', ''))}
              >
                {subMenuItem.title}
              </Link>
            </li>
          )
      )}
    </ul>
  );
};
