import { getLocale } from 'next-intl/server';

import { auth } from '@shared/misc';

import { getLocalPaths } from '../../utils/data';
import { getProfilePicture } from '../../utils/data/get-profile-picture';
import { getMembershipInformation } from '../../utils/helpers';
import { getCardsSectionData } from '../../utils/helpers/get-cards-section-data';
import { getProfileDisplayName } from '../../utils/helpers/get-profile-display-name';
import { getLanguagesContent } from '../../utils/hooks/get-languages-content';

import {
  getHeaderContent,
  getHeaderProfileDropdownContent,
  getHeaderNavigationLinks,
} from './graphql';
import { HeaderClient } from './header-client';
import { getNavigationLinksByPageType } from './utils/helpers';

export const Header = async () => {
  const session = await auth();
  const isAuthenticated = session !== null;
  const personId = session?.personId;
  const localeURLSegment = await getLocale();

  const [
    { applicationDefinitionData, customerProfileData, membershipApplicationData },
    languagesCmsContent,
    cmsContent,
    headerNavigationLinks,
    profileDropdownCmsContent,
  ] = await Promise.all([
    personId
      ? getCardsSectionData(personId)
      : {
          applicationDefinitionData: null,
          customerProfileData: null,
          membershipApplicationData: null,
        },
    getLanguagesContent(),
    getHeaderContent(),
    getHeaderNavigationLinks(),
    getHeaderProfileDropdownContent(),
  ]);

  const pictureName = customerProfileData?.profilePicture?.originalSizePictureUri;

  const [profilePicture, navigationItems] = await Promise.all([
    pictureName ? await getProfilePicture({ personId, pictureName }) : null,
    getNavigationLinksByPageType(headerNavigationLinks, localeURLSegment),
  ]);

  const profileDisplayName = getProfileDisplayName(customerProfileData);

  const paths = await getLocalPaths();

  let statusChipLabel;

  if (customerProfileData) {
    const { isNewUser, isPreviousButNotCurrentMember } = getMembershipInformation({
      applicationDefinition: applicationDefinitionData,
      customerProfile: customerProfileData,
      membershipApplication: membershipApplicationData,
    });

    if (isNewUser) {
      statusChipLabel = cmsContent.becomeMemberLabel;
    }

    if (isPreviousButNotCurrentMember) {
      statusChipLabel = cmsContent.renewNowLabel;
    }
  }

  const profileSummary = {
    displayName: profileDisplayName,
    mdrtId: customerProfileData?.mdrtId || null,
    photoUrl: profilePicture,
  };

  const headerContentProps = {
    homeLabel: cmsContent.homeLabel,
    isAuthenticated,
    languagesCmsContent,
    localeURLSegment,
    profileDropdownCmsContent,
    profileSummary,
    statusChipLabel,
    statusChipUrl: cmsContent.membershipPageLink,
  };

  return (
    <HeaderClient
      headerContentProps={headerContentProps}
      navigationItems={navigationItems}
      paths={paths}
      showHeader={!customerProfileData && Boolean(session)}
    />
  );
};
