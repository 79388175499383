import { type DropdownMenuProps } from '@shared/ui-components';

import { getShareDropdownCmsContent } from './get-share-dropdown-cms-content';
import { ShareDropdownClient } from './share-dropdown-client';

export type ShareDropdownProps = Pick<DropdownMenuProps, 'menuPlacement'> & {
  dataTestId?: string;
  title?: string | null;
  url?: string | null;
};

export const ShareDropdown = async (props: ShareDropdownProps) => {
  const cmsContent = await getShareDropdownCmsContent();

  return <ShareDropdownClient cmsContent={cmsContent} {...props} />;
};
