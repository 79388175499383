import { getLocale } from 'next-intl/server';

import { getCustomerOptimizelyRolesPageFilter } from '../../get-customer-optimizely-roles';

import { convertUrlSegmentToLanguageName } from './convert-url-segment-to-language-name';

export const getCommonSearchQueryFilters = async ({
  xForwardedForHeader,
}: {
  xForwardedForHeader: string;
}) => {
  const localeURLSegment = await getLocale();
  const languageName = convertUrlSegmentToLanguageName(localeURLSegment);

  return {
    ExcludeFromSearch: {
      notEq: true,
    },
    Language: {
      Name: {
        eq: languageName,
      },
    },
    Status: {
      eq: 'Published',
    },
    ...(
      await getCustomerOptimizelyRolesPageFilter({
        xForwardedForHeader,
      })
    ).where,
  };
};
