/* eslint-disable complexity */
import { type PlayerCmsContent } from './graphql/get-player-cms-content';

export const getPlayerDefaultLayoutTranslations = (cmsContent: PlayerCmsContent) => ({
  Accessibility: cmsContent.AccessibilityLabel || 'Accessibility',
  AirPlay: cmsContent.AirPlayLabel || 'AirPlay',
  Announcements: cmsContent.AnnouncementsLabel || 'Announcements',
  Audio: cmsContent.AudioLabel || 'Audio',
  Auto: '',
  Boost: cmsContent.BoostLabel || 'Boost',
  'Caption Styles': '',
  Captions: '',
  'Captions look like this': '',
  Chapters: '',
  'Closed-Captions Off': '',
  'Closed-Captions On': '',
  Color: '',
  Connected: '',
  Connecting: '',
  Continue: cmsContent.ContinueLabel || 'Continue',
  Default: '',
  Disabled: '',
  Disconnected: '',
  'Display Background': '',
  Download: '',
  'Enter Fullscreen': cmsContent.EnterFullscreenLabel || 'Enter Fullscreen',
  'Enter PiP': '',
  'Exit Fullscreen': cmsContent.ExitFullscreenLabel || 'Exit Fullscreen',
  'Exit PiP': '',
  Family: '',
  Font: '',
  Fullscreen: cmsContent.FullscreenLabel || 'Fullscreen',
  'Google Cast': cmsContent.GoogleCastLabel || 'Google Cast',
  'Keyboard Animations': cmsContent.KeyboardAnimationsLabel || 'Keyboard Animations',
  LIVE: '',
  Loop: cmsContent.LoopLabel || 'Loop',
  Mute: cmsContent.MuteLabel || 'Mute',
  Normal: cmsContent.NormalLabel || 'Normal',
  Off: cmsContent.OffLabel || 'Off',
  Opacity: '',
  Pause: cmsContent.PauseLabel || 'Pause',
  PiP: '',
  Play: cmsContent.PlayLabel || 'Play',
  Playback: cmsContent.PlaybackLabel || 'Playback',
  Quality: '',
  Replay: cmsContent.ReplayLabel || 'Replay',
  Reset: '',
  Seek: cmsContent.SeekLabel || 'Seek',
  'Seek Backward': cmsContent.SeekBackwardLabel || 'Seek Backward',
  'Seek Forward': cmsContent.SeekForwardLabel || 'Seek Forward',
  Settings: cmsContent.SettingsLabel || 'Settings',
  Shadow: '',
  Size: '',
  'Skip To Live': '',
  Speed: cmsContent.SpeedLabel || 'Speed',
  Text: '',
  'Text Background': '',
  Track: '',
  Unmute: cmsContent.UnmuteLabel || 'Unmute',
  Volume: cmsContent.VolumeLabel || 'Volume',
});
