import { QueryClient } from '@tanstack/react-query';

import { getLocalPaths } from '../../../utils/data';
import { useHeaderContentQuery } from '../../../utils/data/graphql/_generated/gql-generated';
import { getContentInCurrentLanguage } from '../../../utils/data/graphql/utils/get-content-in-current-language';

export const getHeaderContent = async () => {
  const paths = await getLocalPaths();
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery({
    queryFn: useHeaderContentQuery.fetcher(),
    queryKey: useHeaderContentQuery.getKey(),
  });

  const siteSettingsPageItems = await getContentInCurrentLanguage(data.SiteSettingsPage?.items);
  const homePageItems = await getContentInCurrentLanguage(data.HomePageV2?.items);

  const cmsContent = {
    ...siteSettingsPageItems?.[0],
    ...homePageItems?.[0],
  };

  return {
    becomeMemberLabel: cmsContent?.BecomeMemberLabel || 'Become a member',
    homeLabel: cmsContent?.HomeLabel || 'Home',
    membershipPageLink:
      cmsContent?.MembershipPageLink?.LinkToContent || paths.membershipApplicationPagePath,
    renewNowLabel: cmsContent?.RenewNowLabel || 'Renew now',
  };
};

export type HeaderContent = Awaited<Promise<PromiseLike<ReturnType<typeof getHeaderContent>>>>;
