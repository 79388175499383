import classNames from 'classnames';
import { type HTMLAttributes, type PropsWithChildren } from 'react';

import styles from './content-container.module.scss';

type ContentContainerProps = {
  containerContentTestId?: string;
  isWide?: boolean;
} & PropsWithChildren &
  HTMLAttributes<HTMLDivElement>;

export const ContentContainer = ({
  children,
  containerContentTestId,
  isWide,
}: ContentContainerProps) => {
  return (
    <div
      className={classNames(styles['content-container'], {
        [styles['content-container--wide']]: isWide,
      })}
    >
      <div
        className={classNames(styles['content-container__content'], {
          [styles['content-container__content--wide']]: isWide,
        })}
        data-test-id={containerContentTestId}
      >
        {children}
      </div>
    </div>
  );
};
