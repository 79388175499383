'use client';

import classNames from 'classnames';
import { useRef, type MutableRefObject, type RefObject } from 'react';
import type CarouselComponent from 'react-multi-carousel';

import { ArrowIcon } from '@shared/ui-components';
import {
  GTM_EVENT_NAME_SLIDER_NAV_CLICK,
  GTM_HERO_CAROUSEL_HEADER_VALUE,
  sendGtmEvent,
} from '@shared/utils';

import { useGlobalCmsContentContext } from '../../providers/global-cms-context';

import styles from './carousel-arrow-buttons.module.scss';

export const CarouselArrowButtons = ({
  carouselRef,
  className,
  currentSlide,
  isSecondary,
  slidesToShow,
}: {
  carouselRef?: RefObject<CarouselComponent>;
  className?: string;
  currentSlide: number;
  isSecondary?: boolean;
  slidesToShow: number;
}) => {
  const arrowsContainerRef = useRef<HTMLDivElement>(null);
  const { cmsContent } = useGlobalCmsContentContext();
  const carouselRefOverride = carouselRef as MutableRefObject<
    Omit<CarouselComponent, 'next' | 'previous'> & { next: () => void; previous: () => void }
  >;

  const totalItems = carouselRef?.current?.state.totalItems ?? 0;

  const handleGTMSlideEvent = () =>
    sendGtmEvent({
      eventName: GTM_EVENT_NAME_SLIDER_NAV_CLICK,
      label:
        arrowsContainerRef.current?.previousElementSibling?.querySelector('h2')?.innerText ||
        GTM_HERO_CAROUSEL_HEADER_VALUE,
    });

  return (
    <div
      className={classNames(
        styles['carousel-arrow-buttons'],
        {
          [styles['carousel-arrow-buttons--secondary']]: isSecondary,
        },
        className
      )}
      ref={arrowsContainerRef}
    >
      <button
        aria-label={cmsContent.carouselPreviousButtonLabel}
        className={styles['carousel-arrow-buttons__button']}
        disabled={currentSlide === 0}
        onClick={() => {
          carouselRefOverride?.current?.previous?.();
          handleGTMSlideEvent();
        }}
      >
        <ArrowIcon className={styles['carousel-arrow-buttons__arrow']} width={18} />
      </button>
      <button
        aria-label={cmsContent.carouselNextButtonLabel}
        className={styles['carousel-arrow-buttons__button']}
        disabled={currentSlide === totalItems - slidesToShow}
        onClick={() => {
          carouselRefOverride?.current?.next?.();
          handleGTMSlideEvent();
        }}
      >
        <ArrowIcon
          className={classNames([
            styles['carousel-arrow-buttons__arrow'],
            styles['carousel-arrow-buttons__arrow--right'],
          ])}
          width={18}
        />
      </button>
    </div>
  );
};
