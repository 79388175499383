import { type FieldValues } from 'react-hook-form';

import {
  FieldController as BaseFieldController,
  type FieldControllerProps,
} from '@shared/ui-components';

import { useGlobalCmsContentContext } from '../providers/global-cms-context';

export const FieldController = <T extends FieldValues>(props: FieldControllerProps<T>) => {
  const { cmsContent } = useGlobalCmsContentContext();

  return <BaseFieldController {...props} optionalLabel={cmsContent.optionalLabel} />;
};
