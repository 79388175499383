'use client';

import { CardLink as CardLinkBase, type CardLinkProps } from '@shared/ui-components';

import {
  type CustomLinkComponentProps,
  withCustomLinkComponent,
} from '../../i18n/with-custom-link-component';

export const CardLink = (
  props: Omit<CardLinkProps, 'LinkComponent'> & Partial<CustomLinkComponentProps>
) => withCustomLinkComponent(CardLinkBase, props);
