import { getLocale } from 'next-intl/server';

import { DEFAULT_LOCALE } from '../../../../i18n/locales';

import { convertUrlSegmentToLanguageName } from './convert-url-segment-to-language-name';

export const getContentInstanceInClosestLanguage = async <
  T1 extends { Name?: string | null | undefined },
  T2 extends { Language?: T1 | null | undefined }
>(
  items: Array<T2 | null> | null | undefined
) => {
  if (items === undefined || items === null || items.length === 0) {
    return undefined;
  }

  const localeURLSegment = await getLocale();
  const locale = convertUrlSegmentToLanguageName(localeURLSegment);

  return (
    items.find((item) => item?.Language?.Name === locale) ??
    items.find((item) => item?.Language?.Name === DEFAULT_LOCALE) ??
    items[0]
  );
};
