import { auth } from '@shared/misc';
import { logException } from '@shared/utils';

import { OrgSharedDataTag } from './constants/tags';

export const getProfilePicture = async ({
  personId,
  pictureName,
}: {
  personId: number | undefined;
  pictureName: string | undefined;
}) => {
  try {
    const session = await auth();

    const result = await fetch(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/${pictureName}`,
      {
        cache: 'force-cache',
        headers: {
          Authorization: `Bearer ${session?.accessToken}`,
          'Ocp-Apim-Subscription-Key': process.env.NEXT_PUBLIC_OCP_API_SUBSCRIPTION_KEY ?? '',
        },
        next: {
          tags: [
            OrgSharedDataTag.GET_PROFILE_PICTURE,
            `${OrgSharedDataTag.GET_PROFILE_PICTURE}_${personId}`,
            `${OrgSharedDataTag.GET_PROFILE_PICTURE}_${personId}_${pictureName}`,
          ],
        },
      }
    );

    if (result.status === 404) return null;

    const blob = await result.blob();
    const buffer = Buffer.from(await blob.arrayBuffer());
    return 'data:' + blob.type + ';base64,' + buffer.toString('base64');
  } catch (error) {
    // TODO implement error handling
    logException(error);
    throw error;
  }
};
