import { useCssVariable } from '@shared/utils';

import { useBreakpoint } from './use-breakpoint';

// this hooks returns a dynamic --container-spacing-inline css variable value for usage in JS. It refreshes the value on breakpoint change.

export const useContainerSpacingInline = () => {
  const { breakpoint } = useBreakpoint();

  const [containerSpacingInlineCssVariable] = useCssVariable('--container-spacing-inline', {
    dependencies: [breakpoint],
  });

  return Number.parseInt(containerSpacingInlineCssVariable.propertyValue, 10);
};
