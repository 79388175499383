import {
  InternalServerError,
  NotAuthenticated,
  NotAuthorized,
  NotFoundError,
  ValidationError,
} from './error-definitions';

export const fetchWithErrorHandling = async <T>(
  apiUrl: string | URL | Request,
  { cache, ...rest }: RequestInit
): Promise<T> => {
  const response = await fetch(apiUrl, {
    ...rest,
    cache: cache ?? 'force-cache',
  });

  if (response.ok) {
    return await response.json();
  }

  const result = await response.json().catch(() => undefined);

  switch (response.status) {
    case 400:
      if (result) {
        throw new ValidationError({
          message: result.message,
          messageTranslationKey: result.messageTranslationKey,
        });
      } else {
        throw new ValidationError({
          message: 'Invalid request.',
        });
      }

    case 401:
      throw new NotAuthenticated();

    case 403:
      throw new NotAuthorized();

    case 404:
      throw new NotFoundError();

    default:
      throw new InternalServerError();
  }
};
