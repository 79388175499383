'use client';

import { LoaderStrip } from '@shared/ui-components';

import { useGlobalLoaderContext } from '../../../providers/global-loader-context';

export const LoaderStripContainer = () => {
  const { isNonBlockingLoaderVisible } = useGlobalLoaderContext();

  return <>{isNonBlockingLoaderVisible && <LoaderStrip />}</>;
};
