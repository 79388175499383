import { auth } from '@shared/misc';

import { withErrorHandling, getApiHeaders } from '../helpers';
import { fetchData } from '../helpers/fetch-data';

import { OrgSharedDataTag } from './constants/tags';

export type GetCustomerOptimizelyRolesRequest = {
  xForwardedForHeader: string;
};

export const getCustomerOptimizelyRoles = async ({
  xForwardedForHeader,
}: GetCustomerOptimizelyRolesRequest): Promise<string[]> => {
  const session = await auth();
  const personId = session?.personId;

  if (!personId) {
    return withErrorHandling(
      await fetchData({
        apiUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/CustomerProfile/AnonymousOptimizelyRoles`,
        headers: {
          'Ocp-Apim-Subscription-Key': process.env.NEXT_PUBLIC_OCP_API_SUBSCRIPTION_KEY ?? '',
          'X-Forwarded-For': xForwardedForHeader,
        },
        next: {
          tags: [
            OrgSharedDataTag.GET_CUSTOMER_OPTIMIZELY_ROLES,
            `${OrgSharedDataTag.GET_CUSTOMER_OPTIMIZELY_ROLES}_${xForwardedForHeader}`,
          ],
        },
      }),
      // eslint-disable-next-line no-template-curly-in-string
      '${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/CustomerProfile/AnonymousOptimizelyRoles'
    );
  }

  return withErrorHandling(
    await fetchData({
      apiUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/CustomerProfile/${personId}/OptimizelyRoles`,
      headers: {
        'X-Forwarded-For': xForwardedForHeader,
        ...getApiHeaders(session),
      },
      next: {
        tags: [
          OrgSharedDataTag.GET_CUSTOMER_OPTIMIZELY_ROLES,
          `${OrgSharedDataTag.GET_CUSTOMER_OPTIMIZELY_ROLES}_${personId}`,
          `${OrgSharedDataTag.GET_CUSTOMER_OPTIMIZELY_ROLES}_${xForwardedForHeader}`,
        ],
      },
    }),
    // eslint-disable-next-line no-template-curly-in-string
    '${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/CustomerProfile/${personId}/OptimizelyRoles'
  );
};

export const getCustomerOptimizelyRolesPageFilter = async (
  getOptimizelyRolesRequest: GetCustomerOptimizelyRolesRequest
) => {
  const optimizelyRoles = await getCustomerOptimizelyRoles(getOptimizelyRolesRequest);

  return {
    where: {
      AssignedGroupsReadAccess: {
        GroupName: {
          in: optimizelyRoles,
        },
      },
    },
  };
};
