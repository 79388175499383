import { useEffect, useState } from 'react';

import { breakpoints } from '../constants';
import { Breakpoint } from '../enums';

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint | undefined>(undefined);

  /**
   * Considered as a desktop screen size when matches `Medium` or `Large`, 'XLarge' breakpoints
   */
  const isDesktop =
    breakpoint === Breakpoint.Medium ||
    breakpoint === Breakpoint.Large ||
    breakpoint === Breakpoint.XLarge;

  useEffect(() => {
    const mediaQueries = [
      {
        mediaQuery: window.matchMedia(`(max-width: ${breakpoints[Breakpoint.Small] - 1}px)`),
        size: Breakpoint.XSmall,
      },
      {
        mediaQuery: window.matchMedia(
          `(min-width: ${breakpoints[Breakpoint.Small]}px) and (max-width: ${
            breakpoints[Breakpoint.Medium] - 1
          }px)`
        ),
        size: Breakpoint.Small,
      },
      {
        mediaQuery: window.matchMedia(
          `(min-width: ${breakpoints[Breakpoint.Medium]}px) and (max-width: ${
            breakpoints[Breakpoint.Large] - 1
          }px)`
        ),
        size: Breakpoint.Medium,
      },
      {
        mediaQuery: window.matchMedia(`(min-width: ${breakpoints[Breakpoint.Large]}px)`),
        size: Breakpoint.Large,
      },
      {
        mediaQuery: window.matchMedia(`(min-width: ${breakpoints[Breakpoint.XLarge]}px)`),
        size: Breakpoint.XLarge,
      },
    ];

    const checkMatch = () => {
      for (const { size, mediaQuery } of mediaQueries) {
        if (mediaQuery.matches) {
          setBreakpoint(size);
        }
      }
    };

    for (const { mediaQuery } of mediaQueries) {
      if (mediaQuery.addEventListener) {
        mediaQuery.addEventListener('change', checkMatch);
      } else {
        mediaQuery.addListener(checkMatch);
      }
    }

    checkMatch();

    return () => {
      for (const { mediaQuery } of mediaQueries) {
        if (mediaQuery.removeEventListener) {
          mediaQuery.removeEventListener('change', checkMatch);
        } else {
          mediaQuery.removeListener(checkMatch);
        }
      }
    };
  }, []);

  return {
    breakpoint,
    isDesktop,
  };
};
