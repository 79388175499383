import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  PinterestIcon,
  SoundcloudIcon,
  TwitterIcon,
  WordpressIcon,
  YoutubeIcon,
} from '@shared/ui-components';

import { SocialIcon } from '../enums';

export const IconMap = {
  [SocialIcon.Facebook]: FacebookIcon,
  [SocialIcon.Instagram]: InstagramIcon,
  [SocialIcon.Linkedin]: LinkedinIcon,
  [SocialIcon.Pinterest]: PinterestIcon,
  [SocialIcon.Soundcloud]: SoundcloudIcon,
  [SocialIcon.Twitter]: TwitterIcon,
  [SocialIcon.Wordpress]: WordpressIcon,
  [SocialIcon.Youtube]: YoutubeIcon,
};
