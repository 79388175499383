import { type ChipTheme } from '@shared/ui-components';

import { Chip } from '../../../chip';

type StatusChipProps = {
  label: string | undefined;
  theme?: ChipTheme;
  url: string;
};

export const StatusChip = ({ label, theme, url }: StatusChipProps) => {
  if (!label) return null;

  return <Chip label={label} theme={theme} to={url} />;
};
