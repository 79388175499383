import { auth } from '@shared/misc';

import { fetchData, withErrorHandling } from '../helpers';

import { OrgSharedDataTag } from './constants/tags';

type LearnCardsForYouData = Array<{
  contentId: string;
  order: number;
}>;

type LearnCardsForYouDataProps = {
  cardsLimit: number;
  locale: string;
  personId?: number;
};

export const getLearnCardsForYouData = async ({
  personId,
  cardsLimit,
  locale,
}: LearnCardsForYouDataProps): Promise<LearnCardsForYouData | null> => {
  const session = await auth();

  const response = await fetchData({
    apiUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}/${
      process.env.NEXT_PUBLIC_API_RECOMMENDATIONS_ROUTE ?? 'recommendations-api'
    }/Recommendations/${personId}?lang=${locale}&limit=${cardsLimit}`,
    cache: 'no-cache',
    headers: {
      Authorization: session ? `Bearer ${session?.accessToken}` : '',
      'Ocp-Apim-Subscription-Key':
        process.env.NEXT_PUBLIC_OCP_RECOMMENDATIONS_SUBSCRIPTION_KEY ?? '',
    },
    next: {
      tags: [
        OrgSharedDataTag.GET_RECOMMENDED_ARTICLES_FOR_YOU,
        `${OrgSharedDataTag.GET_RECOMMENDED_ARTICLES_FOR_YOU}_${personId}`,
      ],
    },
  });

  if (response?.status === 404) {
    return null;
  }

  return withErrorHandling(
    response,
    // eslint-disable-next-line no-template-curly-in-string
    "${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_RECOMMENDATIONS_ROUTE ?? 'recommendations-api'}/Recommendations/${personId}?lang=${locale}&limit=${cardsLimit}"
  );
};
