'use client';
import { PropsWithChildren } from 'react';

import {
  GTM_ACTION_DOWNLOAD,
  GTM_EVENT_NAME_APP_DOWNLOAD,
  GTM_EVENT_NAME_AUTHOR_RESOURCELIST_CLICK,
  GTM_EVENT_NAME_OUTBOUND,
  GTM_EVENT_NAME_PDF_DOWNLOAD,
  GTM_EVENT_NAME_PHONE_CALL,
  GTM_EVENT_NAME_SOCIAL_ICONS,
  sendGtmEvent,
} from '@shared/utils';

const handleGTMClickEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  const element = (event.target as HTMLElement).closest('a');
  const isMiddleClick = event.button === 1;

  if (!element || element.tagName !== 'A' || (element.type === 'auxclick' && !isMiddleClick))
    return;

  if (element.getAttribute('data-ga-author-resourcelist-click')) {
    sendGtmEvent({ eventName: GTM_EVENT_NAME_AUTHOR_RESOURCELIST_CLICK, label: element.innerText });
  }

  if (element.getAttribute('data-ga-social-icon')) {
    sendGtmEvent({ eventName: GTM_EVENT_NAME_SOCIAL_ICONS, label: element.ariaLabel || '' });
  }

  if (element.getAttribute('data-ga-phone-call')) {
    sendGtmEvent({ eventName: GTM_EVENT_NAME_PHONE_CALL, label: GTM_EVENT_NAME_PHONE_CALL });
  }

  const download_app = element.getAttribute('data-ga-app-download');
  if (download_app) {
    sendGtmEvent({ eventName: GTM_EVENT_NAME_APP_DOWNLOAD, label: download_app });
  }

  // NOTE: splitting by ? to get href value without parameters if they exist
  const href = element.href.split('?')[0];

  if (href.endsWith('.pdf')) {
    sendGtmEvent({
      action: GTM_ACTION_DOWNLOAD,
      eventName: GTM_EVENT_NAME_PDF_DOWNLOAD,
      label: element.href.slice(element.href.lastIndexOf('/') + 1),
    });
  }

  const isInternalLink =
    new URL(href).hostname.endsWith('mdrt.org') || href.includes(window.location.origin);
  if (href.startsWith('http') && !isInternalLink) {
    sendGtmEvent({
      eventName: GTM_EVENT_NAME_OUTBOUND,
      label: element.href,
    });
  }
};

export const GTMClickTracker = ({ children }: PropsWithChildren) => {
  return (
    <div
      onAuxClick={(event) => handleGTMClickEvent(event)}
      onClick={(event) => handleGTMClickEvent(event)}
    >
      {children}
    </div>
  );
};
