'use client';

import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import { Loader } from '@shared/ui-components';

type GlobalLoaderContextValue = {
  isNonBlockingLoaderVisible: boolean;
  setIsBlockingLoaderVisible: Dispatch<SetStateAction<boolean>>;
  setIsNonBlockingLoaderVisible: Dispatch<SetStateAction<boolean>>;
};

const GlobalLoaderContext = createContext<GlobalLoaderContextValue | undefined>(undefined);

export const GlobalLoaderContextProvider = ({ children }: PropsWithChildren) => {
  const [isBlockingLoaderVisible, setIsBlockingLoaderVisible] = useState(false);
  const [isNonBlockingLoaderVisible, setIsNonBlockingLoaderVisible] = useState(false);
  const value = useMemo(
    () => ({
      isNonBlockingLoaderVisible,
      setIsBlockingLoaderVisible,
      setIsNonBlockingLoaderVisible,
    }),
    [isNonBlockingLoaderVisible]
  );

  return (
    <GlobalLoaderContext.Provider value={value}>
      <>
        {children}
        {isBlockingLoaderVisible && <Loader />}
      </>
    </GlobalLoaderContext.Provider>
  );
};

export const useGlobalLoaderContext = () => {
  const value = useContext(GlobalLoaderContext);

  if (!value) {
    throw new Error(
      `${useGlobalLoaderContext.name} must be used inside of ${GlobalLoaderContextProvider.name}`
    );
  }

  return value;
};
