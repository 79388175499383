import { QueryClient } from '@tanstack/react-query';

import { useShareDropdownCmsContentQuery } from '../../utils/data/graphql/_generated/gql-generated';
import { getContentInCurrentLanguage } from '../../utils/data/graphql/utils/get-content-in-current-language';

export const getShareDropdownCmsContent = async () => {
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery({
    queryFn: useShareDropdownCmsContentQuery.fetcher(),
    queryKey: useShareDropdownCmsContentQuery.getKey(),
  });

  const shareDropdownSmsContent = (
    await getContentInCurrentLanguage(data.SiteSettingsPage?.items)
  )?.[0];

  return {
    close: shareDropdownSmsContent?.Close || 'Close',
    copyLink: shareDropdownSmsContent?.CopyLink || 'Copy link',
    emailResource: shareDropdownSmsContent?.EmailResource || 'Email resource',
    linkCopiedSuccessfully:
      shareDropdownSmsContent?.LinkCopiedSuccessfully || 'Link copied successfully',
    shareOnFacebook: shareDropdownSmsContent?.ShareOnFacebook || 'Share on Facebook',
    shareOnLinkedIn: shareDropdownSmsContent?.ShareOnLinkedIn || 'Share on LinkedIn',
    shareOnX: shareDropdownSmsContent?.ShareOnX || 'Share on X',
    shareResourceMenuTrigger: shareDropdownSmsContent?.ShareResourceMenuTrigger || 'Share resource',
    somethingWentWrong:
      shareDropdownSmsContent?.SomethingWentWrong || 'Something went wrong, please try again later',
  };
};

export type ShareDropdownCmsContent = Awaited<
  Promise<PromiseLike<ReturnType<typeof getShareDropdownCmsContent>>>
>;
